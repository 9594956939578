@import 'ext/fonts';
@import 'ext/normalize';
@import 'begin';
@import 'syntax';

$linkColor: #222222c2;
$linkHoverColor: #c53c2a;
$footerHeight: 240px;
$mobileW: 768px;
$smallMobileW: 480px;
$bigScreenW: 1600px;
$leftWidth: 220px;

$gray: #999;
$lightGray: #eee;

html,body {
	height: 100%;
	margin: 0;
	padding: 0;
}

#page-container {
	position: relative;
	min-height: 100%;
}

#content-wrap {
	padding-bottom: $footerHeight;    /* Footer height */
}

*,*:before,*:after {
	box-sizing: border-box;
}

body {
	font-family: 'Nunito Sans', sans-serif;
	@include fluidType(16px, 19px);
	line-height: 1.55em;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: $linkColor;
	text-decoration: none;
	transition: border-bottom 0.4s;
	&:hover {
		color: $linkHoverColor;
	}
}


h1, h2, h3 {
	line-height: 1.2em;
}

h1 {
	@include fluidType(36px, 48px);
}

h2 {
	font-weight: bold;
	@include fluidType(28px, 32px);
}

h4 {
	font-weight: normal;
	@include fluidType(18px, 20px);
}

figcaption {
	font-size: 10pt;
	line-height: 1.5em;
	margin-top: -1em;
	text-align: center;
	font-style: italic;
}

header {
	// background-size: cover;
	padding: 60px 0;
	color: #fff;
	overflow: hidden;
	h2 {
		font-weight: normal;
		margin-top: 5px;
	}
	h1 {
		margin: 0;
	}

	a.title {
		background-image: url('/assets/logo_technopreneur.png');
		background-repeat: no-repeat;
		padding-left: 60px;
		font-family: 'Audiowide', Arial, Helvetica, sans-serif, cursive;
		color: #fff;
		text-decoration: none;
		&:hover {
			color: #fff;
		}
		.white {
			color: #fff;
		}
		.red {
			color: $linkHoverColor;
		}
	}

	.social {
		overflow: hidden;
		list-style-type: none;
		padding: 0;
		margin-top: 0;
		li {
			color: #fff;
			float: left;
			i {
				&:first-child::before {
					margin-left: 0;
				}
				@include fluidType(26px, 34px);
				transition: color .3s;
				&:hover {
					color: $gray;
				}
			}
		}
	}
}

.clearfix {
	clear: both;
}

//
// Basic Container Class
//
.container {
	margin:0 150px;
	max-width: 720px;
	text-align: justify;

	@media screen and  (max-width: $mobileWidth) {
		margin:0 15px;
	}

	@media screen and (max-width: $tabWidth) and (min-width: $mobileWidth){
		margin:0 40px;	
	}

	@media screen and (max-width: $desktopWidth) and (min-width: $tabWidth){
		margin:0 80px;
	}

	@media screen and (min-width: $desktopWidth) and (max-width: 1280px) {
		margin:0 100px;
	}
}

.post-title {
	color: #222;
	transition: color .3s ease;
	margin: .1em 0;
	text-align: left;
	&:hover {
		color: $gray;
		border-bottom: 0;
	}
}

.post-description {
	font-weight: normal;
	color: #ddd;

	margin-top:.5em;
	margin-bottom: 1.3em;
}

.post-date {
	color: $linkColor;
	float: left;
	.icon-calendar {
		margin-right: 6px;
	}
}

.post-categories {
	float: left;
	color: $gray;
}

.category-item {
	.post-link {
		margin-left: 5px;
	}
}

.post-tags {
	list-style-type: none;
	padding: 0;
	margin: 0;
	li {
		float: left;
		margin-left: 10px;
		&:first-child {
			margin-left: 5px;
		}
		a {
			&:before { content: "#"; }
		}
	}
}

.post-content {
	clear: both;
	padding-top: 20px;
}

.posts {
	list-style-type: none;
	padding: 0;
	>li {
		margin-bottom: 30px;
		padding-bottom: 20px;
		&:not(:last-child) {
			border-bottom: 1px solid $lightGray;
		}
	}
}

.posts, .post-container {
	margin-top: 40px;
}

.post-meta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	a:hover {
		border-bottom: none;
	}
}

.post-container {
	img {
		margin: 20px 0;
		height: auto;
	}
}

.post {
	clear: both;
	padding: 10px 0;
}

.navbar {
	display: flex;
	position: absolute;
	top: 0;
	right: 5vw;
	a {
		color: inherit;
	}
	ul {
		list-style-type: none;
		li {
			// background-color: #ff9f1c;
			border: 1px solid #fff;
			float: left;
			// margin: 5px;
			&:not(:first-child){
				border-left: 0;
			}
			padding: 10px 25px;
			// border-radius: 4px;
			@include fluidType(15px, 17px);
			transition: background-color .3s ease-in;
			&:hover {
				background-color: rgba(#fff, 0.15);
				cursor: pointer;
			}
			&.active {
				background-color: rgba(#fff, 0.15);
			}
		}
	}
}

#categories {
	a.post-link {
		color: $linkHoverColor;
		&:hover {
			color: $linkColor;
		}
	}
}

hr {
    height: 2px;
    padding: 0;
    margin: 1rem 0;
    background-color: #eff0f1;
    border: 0;
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: auto;   /* Height of the footer */

	// background-size: cover;
	// padding: 30px 0;
	color: #fff;

	.container {
		padding: 20px 0;
	}

	p {
		margin: 0;
		padding: 0;
	}

	a, .social li i {
		color: $gray;
		transition: color .3s;
		&:hover {
			color: $linkHoverColor;
		}
	}

	section.links {
		float: left;
		padding-left: 30px;
		&:first-child {
			padding-left: 0;
		}
		.link-title {
			font-weight: bold;
		}
		ul.links {
			list-style-type: none;
			padding: 0;
			margin-top: 0;
		}
		
	}

	.copyright {
		clear: both;
	}

	.social {
		overflow: hidden;
		list-style-type: none;
		padding: 0;
		margin-top: 0;
		li {
			color: $gray;
			float: left;
			i {
				@include fluidType(26px, 34px);
			}
		}
	}
}