@import 'ext/monokai';

pre.highlight, code {
	font-family: 'Consolas', Menlo, monospace;
	font-size: small;
	line-height: 1.4em;
	tab-size: 4;
}

figure.highlight, div.highlighter-rouge {
	background-color: #272822;
	margin: 0;
	border: none;
	border-radius: 5px;
	box-shadow: 0 4px 30px rgba(0,0,0,.5);
	padding: 0;
	position: relative;
	text-align: left;

	pre code {
		display: block;
		/* Display language */
		&[data-lang]::before {
			content: attr(data-lang);
			font-size: smaller;
			position: absolute;
			right: 15px;
			top: -10px;
			background-color: #272822;
			border: 1px solid #505050;
			border-radius: 5px;
			padding: 2px 5px;
			text-align: center;
		}
	}
}

pre code {
	border: 0;
	padding: 15px 0.5rem;
	white-space: pre;
}

figure.highlight pre {
	code {
		padding: 20px 0.5rem;
		/* Use a counter to generate the line numbers */
		counter-reset: code; 

		div {
			/* Increment the counter for each div */
			counter-increment: code;

			&:before {
				content: counter(code);
				width: 14px; /* Define the width for the line number */
				text-align: right;
				display: inline-block;
				padding-right: 7.5px;
				margin-right: 12px;
				border-right: 1px solid gray;

				/* For older browsers and Safari */
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;	
			}
		}
	}
}